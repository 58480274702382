// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-plan-zajec-js": () => import("./../../../src/pages/plan-zajec.js" /* webpackChunkName: "component---src-pages-plan-zajec-js" */),
  "component---src-pages-szukaj-js": () => import("./../../../src/pages/szukaj.js" /* webpackChunkName: "component---src-pages-szukaj-js" */),
  "component---src-templates-language-post-js": () => import("./../../../src/templates/language-post.js" /* webpackChunkName: "component---src-templates-language-post-js" */),
  "component---src-templates-news-index-js": () => import("./../../../src/templates/news-index.js" /* webpackChunkName: "component---src-templates-news-index-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-study-index-js": () => import("./../../../src/templates/study-index.js" /* webpackChunkName: "component---src-templates-study-index-js" */),
  "component---src-templates-study-post-js": () => import("./../../../src/templates/study-post.js" /* webpackChunkName: "component---src-templates-study-post-js" */)
}

